import { template as template_8f4b73b74e384a8e9bf4b2c646a0b85e } from "@ember/template-compiler";
const FKControlMenuContainer = template_8f4b73b74e384a8e9bf4b2c646a0b85e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
