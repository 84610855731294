import { template as template_006402c169ee4d4c93857bf8b3626f19 } from "@ember/template-compiler";
const SidebarSectionMessage = template_006402c169ee4d4c93857bf8b3626f19(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
