import { template as template_3d281e91df0340fbbba1c8a3cbcbe86e } from "@ember/template-compiler";
const FKLabel = template_3d281e91df0340fbbba1c8a3cbcbe86e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
